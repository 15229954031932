import React from "react"
import { Link } from "gatsby"
import get from "lodash.get"
import Moment from "react-moment"

import LinkLabel from "./link-label"

const Card = ({ article }) => {
  return (
    <div className="uk-card uk-card-default uk-grid-collapse uk-margin uk-grid">
      <div className="uk-card-media-left uk-cover-container uk-width-auto@m">
        <img
          src={article.node.image.publicURL}
          alt={article.node.image.publicURL}
          style={{ width: 310, height: 223 }}
          className="uk-cover"
        />
        <canvas width="310" height="223"></canvas>
      </div>
      <div className="uk-width-expand@m">
        <div className="uk-card-body">
          <h3 className="uk-card-title ">
            <Link
              className="uk-link-heading"
              to={`/article/${article.node.strapiId}`}
            >
              {article.node.title}
            </Link>
          </h3>
          <p className="uk-text-meta ">
            <span uk-icon="pencil"></span> Posted by:{" "}
            <LinkLabel to={`/author/${get(article.node, "author.id")}`}>
              {get(article.node, "author.username")}
            </LinkLabel>
          </p>
          <p className="uk-text-meta ">
            <span uk-icon="folder"></span> Category:{" "}
            <LinkLabel
              className="uk-link-text"
              to={`/category/${get(article.node, "category.id")}`}
            >
              {get(article.node, "category.name")}
            </LinkLabel>
          </p>
          <p className="uk-text-meta">
            <span uk-icon="calendar"></span> Published at{" "}
            <Moment format="MMM Do YYYY">{article.node.published_at}</Moment>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Card
